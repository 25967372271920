import * as React from 'react';
import type { SVGProps } from 'react';

function SvgPlus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 5v14m-7-7h14' />
    </svg>
  );
}
export default SvgPlus;
