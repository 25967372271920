import * as React from 'react';
import type { SVGProps } from 'react';

function SvgClock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M12 6v6l4 2m6-2c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z'
      />
    </svg>
  );
}
export default SvgClock;
