import * as React from 'react';
import type { SVGProps } from 'react';

function SvgPizza(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 14 14' {...props}>
      <path
        fill='currentColor'
        d='M13.971 2.938a.994.994 0 0 0-.453-.62 12.593 12.593 0 0 0-13.036 0 1 1 0 0 0-.336 1.376l6 9.83a1 1 0 0 0 1.71 0l6-9.83a.99.99 0 0 0 .115-.756ZM2.974 6.406a1.5 1.5 0 1 1 1.53 2.506l-1.53-2.506Zm5.495 4.185A1.5 1.5 0 0 1 10 8.086L8.47 10.59Zm2.059-3.371a2.5 2.5 0 0 0-2.587 4.236L7 13 5.031 9.777a2.5 2.5 0 1 0-2.584-4.235l-.397-.65a9.5 9.5 0 0 1 9.9 0L10.527 7.22Zm1.943-3.183a10.508 10.508 0 0 0-10.942 0L1 3.173a11.6 11.6 0 0 1 12 0l-.529.864Z'
      />
    </svg>
  );
}
export default SvgPizza;
