import * as React from 'react';
import type { SVGProps } from 'react';

function SvgSwitchHorizontal01(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M20 17H4m0 0 4-4m-4 4 4 4M4 7h16m0 0-4-4m4 4-4 4'
      />
    </svg>
  );
}
export default SvgSwitchHorizontal01;
