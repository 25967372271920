import * as React from 'react';
import type { SVGProps } from 'react';

function SvgChevronDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='m6 9 6 6 6-6' />
    </svg>
  );
}
export default SvgChevronDown;
