import * as React from 'react';
import type { SVGProps } from 'react';

function SvgXClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M18 6 6 18M6 6l12 12'
      />
    </svg>
  );
}
export default SvgXClose;
