import * as React from 'react';
import type { SVGProps } from 'react';

function SvgCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill='none' viewBox='0 0 24 24' {...props}>
      <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M20 6 9 17l-5-5' />
    </svg>
  );
}
export default SvgCheck;
